import { getMessageLanguageApi } from './api'
import { LANGUAGE_KEYS  } from '@/public/src/pages/common/common-coupon/language.js'
export async function getLang (langPath, callback) {
  let res = await getMessageLanguageApi({
    'pages': ['coupon_item', 'message']
  })

  let couponCmpLang = await schttp({
    url: '/system/configs/multi_language',
    method: 'POST',
    data: {
      languageKeys: LANGUAGE_KEYS
    },
    useBffApi: true
  })
  const result = {...res?.info?.result, ...couponCmpLang?.info?.result }
  callback(result)
}

