export const createUserTicketRoute = (langPath = '') => {
  return [
    {
      path: `${langPath}/user/tickets`,
      name: 'Tickets',
      component: () => import(/* webpackChunkName: "user_ticket_comp" */'../../user/child_pages/tickets/ticketListNew'),
      meta: {
        keepAlive: false,
        isCssInVue: true,
        notNeedCommonHeader: true,
        pageType: 'Tickets',
        monitorTag: 'Tickets',
      },
    },
    {
      path: `${langPath}/user/tickets/store`,
      name: 'TicketsStore',
      component: () => import(/* webpackChunkName: "user_ticket_comp" */'../../user/child_pages/tickets/store/ticketStoreList.vue'),
      meta: {
        keepAlive: false,
        isCssInVue: true,
        notNeedCommonHeader: true,
        pageType: 'TicketsStore',
        monitorTag: 'TicketsStore',
      }
    },
    {
      path: `${langPath}/user/tickets/detail/:id`,
      name: 'TicketDetail',
      component: () => import(/* webpackChunkName: "user_ticket_comp" */'../../user/child_pages/tickets/detail/detailIndex.vue'),
      meta: {
        keepAlive: false,
        pageType: 'TicketDetail',
        monitorTag: 'TicketDetail',
        isCssInVue: true,
        notNeedCommonHeader: true
      },
    },
    {
      path: `${langPath}/user/tickets/reply/:id`,
      name: 'TicketDetailReply',
      component: () => import(/* webpackChunkName: "user_ticket_comp" */'../../user/child_pages/tickets/detail/detailReply.vue'),
      meta: {
        keepAlive: false,
        isCssInVue: true,
        notNeedCommonHeader: true,
        pageType: 'TicketDetailReply',
        monitorTag: 'TicketDetailReply',
      },
    },
  ]
}

