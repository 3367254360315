import { ref, computed } from 'vue'
import { useMapGetters, useMapMutations } from './useVuex.js'
import { Toast } from '@shein/sui-mobile'
import schttp from 'public/src/services/schttp'

// 是否展示过关注成功弹窗的key
const BEEN_POPUP_SHOW_KEY = 'beenPopupStoreFollowSuccess'

function useFollow() {
  const { 
    storeData,
    language,
    isFollowed
  } = useMapGetters([
    'storeData',
    'language',
    'isFollowed'
  ])

  const { 
    updateIsFollowed 
  } = useMapMutations([
    'updateIsFollowed'
  ])

  const storeCode = computed(() => storeData.value.storeCode)
  const signboard = computed(() => storeData.value.signboard || {})
  
  const loading = ref(false)
  const isShowFirstSuccPopup = ref(false)

  const followAction = async () => {
    if (loading.value) return
    loading.value = true
    const action = !isFollowed.value ? 'follow' : 'unfollow'
    try {
      const result = await schttp({
        url: '/product/store/update_following',
        method: 'POST',
        data: {
          action,
          scene: 'store',
          store_code_list: storeCode.value,
        },
        useBffApi: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      const { code } = result
      if (code === '0') {
        updateIsFollowed(!isFollowed.value)
        if (action === 'follow') {
          if (localStorage?.getItem(BEEN_POPUP_SHOW_KEY) === '1') {
            Toast({
              content: language.value.SHEIN_KEY_PWA_24070,
              duration: 3000,
            })
          } else {
            // 第一次提示
            isShowFirstSuccPopup.value = true
            localStorage?.setItem(BEEN_POPUP_SHOW_KEY, '1')
          }
        }
      } else if (String(code) === '00101110') { // 未登录
        if (typeof window !== 'undefined' && typeof SHEIN_LOGIN !== 'undefined') {
          const loginCallback = state => {
            if (String(state?.code) === '0') {
              followAction()
            }
          }
          SHEIN_LOGIN.show({
            show: true,
            bi: 'other',
            cb: loginCallback
          })
        }
      } else if (code === '400429') { // 关注失败，关注数量超出上限
        Toast({
          content: language.value.SHEIN_KEY_PWA_24077,
          duration: 3000,
        })
      } else {
        throw result
      }
    } catch (err) {
      Toast({
        content: action === 'follow' ? language.value.SHEIN_KEY_PWA_24076 : language.value.SHEIN_KEY_PWA_24075,
        duration: 3000,
      })
    }
    // eslint-disable-next-line require-atomic-updates
    loading.value = false
  }

  return {
    isShowFirstSuccPopup,
    isFollowed,
    storeCode,
    signboard,
    language,
    followAction,
  }
}

export default useFollow
