var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('Transition',{attrs:{"name":"storeIconEntry","mode":"out-in"}},[(_setup.showFollowHandleBtn)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
        id: '2-22-20',
        data: { item: _setup.analysisData }
      }),expression:"{\n        id: '2-22-20',\n        data: { item: analysisData }\n      }"},{name:"tap",rawName:"v-tap",value:({
        id: '2-22-21',
        data: { item: _setup.analysisData }
      }),expression:"{\n        id: '2-22-21',\n        data: { item: analysisData }\n      }"}],key:"storeFollowIcon",staticClass:"store-header-follow",class:_setup.followBtnStart ? 'store-header-follow__btn_mini' : '',on:{"click":_setup.followAction}},[_c(_setup.BaseImg,{staticClass:"store-header-follow__logo",attrs:{"first-screen":true,"is-support-webp":true,"img-design-width":60,"img-src":_setup.signboard.store_logo}}),_vm._v(" "),_c('div',{staticClass:"store-header-follow__btn"},[_c('TransitionGroup',{attrs:{"name":"storeIconHandle","mode":"out-in"},on:{"after-enter":_setup.onAfterEnter,"after-leave":_setup.onAfterLeave}},[_c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(_setup.followBtnStart && _setup.followBtnEnd),expression:"followBtnStart && followBtnEnd"}],key:"followed",staticClass:"store-header-follow__btn-followed",attrs:{"name":"sui_icon_selected_12px","size":"0.32rem","color":"#fff"}}),_vm._v(" "),_c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(!_setup.followBtnStart),expression:"!followBtnStart"}],key:"follow",staticClass:"store-header-follow__btn-add",attrs:{"name":"sui_icon_add_12px","size":"0.32rem","color":"#fff"}})],1)],1)],1):_c(_setup.StoreFollowEntry)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }