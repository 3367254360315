
import { computed } from 'vue'
import { useGlobalMapGetters } from './store.js'

export default function useShare({ route }) {
  const changeCardShareIconAbt = computed(() => {
    const fs_abt = useGlobalMapGetters(['productDetail/common/fs_abt'])
    return fs_abt?.collectandaddcart?.p?.collectandaddcart === 'A' // 调整购物车与收藏入口位置abt
  })

  const showWish = computed(() =>{
    //详情页分享
    return ['page_goods_detail'].includes(route.value.name)
    && changeCardShareIconAbt.value
  })
  return {
    showWish
  }
}
