<template>
  <div class="store-header__right">
    <!-- 没有吸顶 -->
    <StoreHeaderMore 
      v-show="!isStoreTopTabSticky"
    />
    <!-- 命中abt -->
    <ClientOnlySlot 
      v-if="isTopfollowbutton"
    >
      <template 
        v-if="isTrendStore"
      >
        <StoreFollowIconBff 
          v-if="storeData.isBff"
          v-show="isStoreTopTabSticky"
        />
        <StoreFollowIcon 
          v-else
          v-show="isStoreTopTabSticky"
        />
      </template>
      <StoreFollowEntry 
        v-else
        v-show="isStoreTopTabSticky"
      />
    </ClientOnlySlot>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StoreHeaderCenter', // 店铺中间
})
</script>
<script setup>
import {  computed } from 'vue'
import ClientOnlySlot from 'public/src/pages/components/ccc/base/ClientOnlySlot.vue'
import { useStorePageData, useInjectStoreHeader } from '../../hooks/useStoreHeader.js'
import StoreHeaderMore from './StoreHeaderMore.vue'
import StoreFollowIcon from './StoreFollowIcon.vue'
import StoreFollowIconBff from './StoreFollowIconBff.vue'
import StoreFollowEntry from './StoreFollowEntry.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '2-9' })
daEventCenter.addSubscriber({ modulecode: '2-22' }) 

const { isStoreTopTabSticky } = useInjectStoreHeader()

const { storeAbtResult, isTrendStore, storeData } = useStorePageData()

const isTopfollowbutton = computed(()=>{
  return storeAbtResult.value.followenhance?.p?.topfollowbutton === 'B'
})

</script>
<style lang="less">
.store-header__right{
  flex-shrink: 0;
}

</style>
