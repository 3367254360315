const { langPath } = gbCommonInfo
const checkoutCashierRoutes = [
  {
    path: `${langPath}/cashier`,
    name: 'page_checkout_cashier',
    meta: {
      keepAlive: false,
      pageType: 'page_checkout_cashier',
      notNeedCommonHeader: true,
      orderType: 'page_checkout_cashier',
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "buy-prime-checkout" */ 'public/src/pages/checkout/page_tpls/checkout-cashier/checkoutCashierTest.vue')
  }
]

export {
  checkoutCashierRoutes
}
