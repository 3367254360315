<template>
  <div
    v-ada="{ level: 0, pos: 0 }"
    class="add-cart__wish-btn j-btn-save"
    :class="{ 'add-cart__wish-btn-bottom': !isFromTopSite }"
    :role="language_v2.SHEIN_KEY_PWA_17952"
    :ariaLabel="ariaLabel"
    @click="handleBtnClick"
  >
    <div>
      <Icon
        name="sui_icon_save_completed_32px"
        :size="size"
        :class="totalClass"
        :style="{ display: !!saveStatus ? 'inline-block' : 'none' }"
      />
      <Icon
        name="sui_icon_save_32px"
        :size="size"
        :class="totalClass"
        :style="{ display: !!saveStatus ? 'none' : 'inline-block' }"
      />
    </div>
    <div
      v-if="showAddBoardBtn"
      v-ada="{level: 0, pos: 0}"
      class="add-board"
      :class="{ 'add-board-bottom': !isFromTopSite, 'add-board-top': isFromTopSite }"
      @click.stop="addBoard"
    > 
      <i class="suiiconfont sui_icon_add_circle_18px"></i>
      {{ language_v2.SHEIN_KEY_PWA_17403 }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue2'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { template, getQueryString } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'

const WISH_STORAGE_KEY = 'goodslist_like_goodsids'
export default {
  components: {
    Icon,
  },
  props: {
    loc: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '32px',
    },
    reviewLocation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // 开始收藏动画状态
      isStartAnimation: false,
      // 结束收藏动画状态
      isEndAnimation: false,
      // 添加至分组按钮是否展示
      showAddBoardBtn: false,
    }
  },
  computed: {
    ...mapState('newProductDetail/common', [
      'quickShip',
      'saveStatus',
      'initExposeStatus',
    ]),
    ...mapGetters('productDetail/common', [
      'language_v2',
      'goods_id',
      'mall_code',
      'sku_info',
      'goods_sn',
      'base_info',
      'fs_abt',
      'is_saved',
    ]),
    ...mapGetters('productDetail/Description', ['trends_info']),
    ariaLabel() {
      return !!this.saveStatus ? this.language_v2.SHEIN_KEY_PWA_15007 : this.language_v2.SHEIN_KEY_PWA_15006
    },
    totalClass() {
      return {
        'add-cart__wish-btn_anim-start': this.isStartAnimation && !this.isEndAnimation,
        'add-cart__wish-btn_anim-end': this.isEndAnimation,
      }
    },
    isFromTopSite() {
      return this.loc === 'topsite'
    },
    wishParams() {
      const data = {
        goods_id: this.goods_id,
        mall_code: this.mall_code,
      }
      if (this.sku_info?.sku_code) {
        data.sku_code = this.sku_info?.sku_code
      }
      return data
    },
  },
  watch: {
    goods_id() {
      this.showAddBoardBtn = false
    },
    is_saved: {
      immediate: true,
      handler() {
        this.updateSaveStatus(!!this.is_saved)
      }
    },
  },
  methods: {
    ...mapActions('productDetail/common', ['show_login_modal']),
    ...mapActions('productDetail/addBag', ['wish_goods']),
    // TODO 旧状态
    ...mapActions('newProductDetail/common', ['updateExposeStatus']),
    ...mapMutations('newProductDetail/common', ['updateSaveStatus']),
    ...mapMutations('wishlist', ['assignWSState']),
    wishLog({
      isRemove, 
      data,
    }) {
      const extraData = {
        result: {
          data,
        },
        code: 'goodsDetail',
        mall_code: this.mall_code || '',
        postData: {
          loc: this.loc || '',
          quickShip: this.quickShip,
          action: isRemove ? 0 : 1,
          goods_id: this.goods_id,
          sku: this.goods_sn,
          index: 0,
        },
        from: 'main',
        review_location: this.reviewLocation || '',
        ...(this.trends_info?.trend_word_id ? {
          passParams: {
            trend_tag: `${this.trends_info.trend_word_id}-${this.trends_info.product_select_id}`
          }
        } : {}),
      }
      if (!isRemove) {
        extraData.postData.detail = this.base_info
        // 从预搜页找相似跳转过来的情况须多加几个参数
        const isFromPreSearchSimilarItems = !!getQueryString({ key: 'preSearchSimilarItems' })
        const srcTabPageId = getQueryString({ key: 'src_tab_page_id' }) || ''
        if (isFromPreSearchSimilarItems && srcTabPageId) {
          Object.assign(
            extraData, {
              src_module: 'presearch_similar',
              src_identifier: '-',
              src_tab_page_id: srcTabPageId
            }
          )
        }
      }
      daEventCenter.triggerNotice({
        daId: '1-8-1-2',
        extraData
      })
    },
    async handleBtnClick() {
      this.isEndAnimation = false
      this.isStartAnimation = true
      this.saveStatus ? this.cancelWish() : this.addWish()
      // TODO 全局都搜不到了，先删
      appEventCenter.$emit('wishReloadProductList')
    },
    async addWish() {
      if (!this.beforeWish()) return
      const data = await this.wish_goods({
        data: this.wishParams,
        isRemove: false,
      }).catch(() => {})
      this.isStartAnimation = false
      this.wishLog({
        isRemove: false,
        data,
      })
      if (data?.code == 400427) {
        // 超出收藏限制
        this.$toast(
          template(
            data?.info?.limit,
            this.language_v2?.SHEIN_KEY_PWA_18556
          ),
          3000
        )
        return
      }
      if (data?.code == 0 && data?.info?.result) {
        this.isEndAnimation = true
        this.updateSaveStatus(true)
        this.updateExposeStatus({ addWishStatus: 1, addBagStatus: this.initExposeStatus.addBagStatus || 0 })
        this.changeStorageLikeGoodsId(true)
        this.assignWSState({
          needUpdateData: true // 需要更新收藏列表
        })

        const hasWishGroup = data?.info?.has_group === 1
        this.showAddBoardBtn = this.fs_abt?.AddShowGroup?.param === 'ShowGroup' || hasWishGroup

        if (this.showAddBoardBtn) {
          daEventCenter.triggerNotice({
            daId: '1-10-2-2',
            extraData: {
              board_count: hasWishGroup ? 1 : 0
            }
          })
          setTimeout(() => {
            this.showAddBoardBtn = false
          }, 5000)
        }
        return
      }
      this.$toast(this.language_v2.SHEIN_KEY_PWA_20051 || data?.msg || 'error', 1000)
    },
    async cancelWish() {
      const data = await this.wish_goods({
        data: this.wishParams,
        isRemove: true,
      }).catch(() => {})
      this.isStartAnimation = false
      this.showAddBoardBtn = false
      this.wishLog({
        isRemove: true,
        data,
      })
      if (data?.code == 0 && data?.info?.result) {
        this.isEndAnimation = true
        this.updateSaveStatus(false)
        this.assignWSState({
          needUpdateData: true // 需要更新收藏列表
        })
        this.changeStorageLikeGoodsId(false)
        return
      }
      this.$toast(
        this.language_v2?.SHEIN_KEY_PWA_20051 || data?.msg || 'error',
        1000
      )
    },
    beforeWish() {
      if (isLogin()) return true
      if (gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD) {
        this.show_login_modal({
          params: {
            from: 'wishlist',
            ga: 'wishlist',
          },
          cb: () => {
            this.addWish()
          },
        })
      }else {
        SHEIN_LOGIN.show({
          show: true,
          from: 'wishlist',
          ga: 'wishlist',
          cb: () => {
            this.updateIsLoginChange(true)
            this.addWish()
          }
        })
      }
      return false
    },
    changeStorageLikeGoodsId(add) {
      const { goods_id: currentGoodsId } = this
      try {
        const goodsId = JSON.parse(
          window.localStorage.getItem(WISH_STORAGE_KEY)
        )

        if (add) {
          // 添加
          if (goodsId && goodsId.length) {
            window.localStorage.setItem(
              WISH_STORAGE_KEY,
              JSON.stringify([...goodsId, Number(currentGoodsId)])
            )
          } else {
            window.localStorage.setItem(
              WISH_STORAGE_KEY,
              JSON.stringify(Array.of(Number(currentGoodsId)))
            )
          }
        } else {
          // 移除
          if (!goodsId) return
          const goodsIdIndex = goodsId.findIndex(
            id => Number(id) === Number(currentGoodsId)
          )
          if (goodsIdIndex !== -1) {
            goodsId.splice(goodsIdIndex, 1)
            window.localStorage.setItem(
              WISH_STORAGE_KEY,
              JSON.stringify(goodsId)
            )
          }
        }
      } catch (err) {
        console.warn(
          'Change wish status localStorage relatives fail',
          err,
        )
      }
    },
    addBoard() {
      let groupList = []
      try {
        groupList = JSON.parse(window.localStorage.getItem('groupList')) || []
      } catch (err) {
        console.warn(
          'parse addBoard localStorage relatives fail',
          err,
        )
      }
      daEventCenter.triggerNotice({
        daId: '1-10-2-3',
        extraData: {
          eventCategory: '商品详情页',
          board_count: groupList.length ? 1 : 0
        }
      })
      this.$_WD({
        type: 6,
        params: {
          goodsIds: [this.goods_id]
        }
      })
    },
  },
}
</script>

<style lang="less">
.add-cart__wish-btn {
  background: #fff;
  position: relative;
  width: 0.85333rem;
  flex: 1 0 0.85333rem;
  height: 1.07rem;
  line-height: 1.07rem;
  text-align: center;
  -webkit-appearance: none;
  .suiiconfont {
    .font-dpr(68px);
  }
  .sui_icon_save_completed_32px,
  .sui_icon_save_completed_34px {
    color: @sui_color_brand;
  }
  &.add-cart__wish-btn-bottom {
    margin-right: 0.32rem;
  }
  &_anim-start {
    &::before {
      display: block;
      animation: goodsdt-like-anim-click 0.3s cubic-bezier(0.4, 0, 0.2, 1)
          forwards,
        goodsdt-like-anim-loading 0.8s cubic-bezier(0.4, 0, 0.2, 1) 0.3s
          infinite;
    }
  }
  &_anim-end {
    &::before {
      display: block;
      animation: goodsdt-like-anim-complete 0.2s ease both;
    }
  }

  .add-board {
    display: flex;
    position: absolute;
    min-width: 3.1067rem;
    height: 0.8533rem;
    line-height: 0.8533rem;
    background: #000;
    padding-left: 0.2666rem;
    padding-right: 0.32rem;
    color: #fff;
    font-size: 12px;
    opacity: 0.8;
    text-overflow: ellipsis;
    white-space: nowrap;
    .zindex-translatez(
      @zindex-attrmenusize,
      translate3d(0, 0, @zindex-attrmenusize)
    );
    /*rw:begin*/
    border-radius: 2px;
    [class*="iconfont"] {
      margin-right: 0.1067rem;
      vertical-align: -2px;
      font-size: 18px;
    }
  }
  .add-board-bottom {
    overflow: hidden;
    animation: add-board-keyframes-inside 500ms forwards ease;
    transform: translate3d(0, 0, 0);
    .left(0);
    top: calc(-1.1733rem - 0.16rem);
  }
  .add-board-top {
    animation: add-board-keyframes-top 500ms forwards ease;
    .right(0);
    top: 1.12rem;
    &::before {
      content: '';
      position: absolute;
      width: 0.2667rem;
      height: 0.2667rem;
      top: -0.13335rem;
      right: 10%;
      margin-left: -0.13335rem;
      background: #000000;
      transform: rotate(45deg);
      .zindex-translatez(
        @zindex-list-hdfilter,
        translate3d(0, 0, @zindex-list-hdfilter)
      );
    }
  }
  @keyframes add-board-keyframes-top {
    0% {
      opacity: 0;
      top: 0;
    }
    100% {
      opacity: 0.8;
      top: 1.12rem;
    }
  }
  @keyframes add-board-keyframes-inside {
    0% {
      opacity: 0;
      top: 0;
    }
    100% {
      opacity: 0.8;
      top: calc(-1.1733rem - 0.16rem);
    }
  }
}
</style>
