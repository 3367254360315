import { isSwitchBffSsr } from 'public/src/pages/common/bff-new/index.js'

export const createStoreFlashSaleRoute = (langPath = '', isBff = false) => {
  return {
    path: `${langPath}/store/flash-sale`,
    name: 'store_flash_sale',
    meta: {
      keepAlive: true,
      pageType: 'store_flash_sale',
      monitorTag: 'store-flash-sale',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => userStoreFsRouteComponent(isBff),
  }
}

export function userStoreFsRouteComponent(isBff) {
  const tag = 'store-flashsale-landing-v1'
  isBff = typeof window === 'undefined' ? isBff : isSwitchBffSsr([tag])?.[tag]
  return isBff ? 
    import( /* webpackChunkName: "store_flash_sale" */ '../../store_flash_sale_v2/container.vue') : 
    import( /* webpackChunkName: "store_flash_sale" */ '../../store_flash_sale/container.vue')
}
