const newPosKeys = [
  'SearchPerformance',
  'MostPopular',
  'MoreSearchWord',
  'NewPicSearch',
  'PicSearchUpgrade',
  'SearchPagefromDetail',
  'HomeSearch',
  'SuggestWordUp',
  'SearchTrendNew',
  'HotWordScroll',
  'BackToSugg',
  'SearchSuggestNew',
  'SearchCouponNoti',
  'PreSearchPic',
  'newpresearch',
  'newpresearchlenovo',
  'newpresearchicon',
  'newsearch', // 搜索框样式强化
  'CatMapping',
  'refreshpresearch', // 搜索结果页返回预搜页是否刷新联想词
]
const posKeys = [
  'SearchSuggestwordFeedback',
  'PicSearch',
  'PicSearchStrategy',
  'PicSearchExpandPop',
  'AllListQuickShip',
  'ListSearchSort',
]

export default [...posKeys, ...newPosKeys]
