var render, staticRenderFns
import script from "./WishBtn.vue?vue&type=script&lang=jsx&"
export * from "./WishBtn.vue?vue&type=script&lang=jsx&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.8.1_ejs@2.7.2_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.86.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "WishBtn.vue"
export default component.exports