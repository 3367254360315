import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import { EventBus } from '../../common/event-bus'

Vue.use(VueRouter)
Vue.use(VueMeta)

export function createRouter (context, { routes, clientFunc, store }) {
  const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
      if (to.meta.ignoreScrollBehavior) {
        delete to.meta.ignoreScrollBehavior
        return
      }
      return window?.appRouteExtData?.direction === -1 && to.matched.some(m => m.meta.keepAlive)
        ? to.meta.savedPosition || savedPosition || { x: 0, y: 0 }
        : { x: 0, y: 0 }
    }
  })
  // 路由进入
  router.beforeEach((to, from, next) => {
    // 触发router-change事件，给子组件监听
    EventBus.$emit('router-change', { to, from, next })
    // for click effect && analysis send
    if (from.meta.isForward) {
      from.meta.isForward = undefined
      next()
    } else {
      next()
    }
  })

  if (clientFunc) clientFunc({ router, store })

  return router
}
