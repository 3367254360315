/**
 * @file 榜单落地页
 */
import { isSwitchBffSsr } from 'public/src/pages/common/bff-new/index.js'

const getRankProductsComponent = useBffApi => {
  const isUseBff = typeof window === 'undefined' ? useBffApi : isSwitchBffSsr(['rankingList_v1'])?.['rankingList_v1']
  return isUseBff
    ? () => import(/* webpackChunkName: "rank-products-page" */ 'public/src/pages/rank_products_v2/Index.vue')
    : () => import(/* webpackChunkName: "rank-products-page" */ 'public/src/pages/rank_products/Container.vue')
}
/**
 * 热销榜、折扣榜
 * @param {string} langPath
 * @returns
 */
export const createRankProductsRoute = (langPath, useBffApi = false) => ({
  path: `${langPath}/ranking_list/page`,
  name: 'page_rank_products',
  meta: {
    pageType: 'page_rank_products',
    isCssInVue: true,
    keepAlive: true,
    monitorTag: 'page_rank_products',
    ignoreScrollBehavior: true,
    notNeedCommonHeader: true
  },
  component: getRankProductsComponent(useBffApi)
})

/**
 * 热销榜单新规则页
 * @param {*} langPath
 * @returns
 */
export const createRankProductsRulesRoute = langPath => ({
  path: `${langPath}/ranking_list/rules`,
  name: 'page_rank_products_rules',
  meta: {
    pageType: 'page_rank_products_rules',
    isCssInVue: true,
    keepAlive: true,
    notNeedCommonHeader: true,
    scrollTop: true,
    monitorTag: 'page_rank_products_rules'
  },
  component: () =>
    import(
      /* webpackChunkName: "rank-products-rules" */ '../../rank_products/RulesContainer.vue'
    )
})
