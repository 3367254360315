<template>
  <a
    class="common-logo-header__wishlist"
    tabindex="0"
    role="link"
    href="javascript:;"
  >
    <WishBtnV2 
      v-if="is_use_bff_fs" 
      :style="{ 'margin-right': 0, color: '#000' }"
      size="24px"
      loc="topsite"
    />
    <WishBtn
      v-else
      :style="{ 'margin-right': 0, color: '#000' }"
      size="24px"
      loc="topsite"
    />
    <div 
      v-if="showMovedTips"
      class="moved-tip"
    >
      <span>{{ language.SHEIN_KEY_PWA_32912 || 'Collection has moved here!' }}</span>
      <Icon
        class="moved-tip__close"
        name="sui_icon_close_18px" 
        size="12px" 
        @click="closeMovedTips"
      />
    </div>
  </a>
</template>

<script setup>
import { onMounted, onUnmounted, computed, ref } from 'vue'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { useGlobalMapState } from '../hooks/store.js'
import ControlAsync from '../analysis/controlAsync.js'
import WishBtn from 'public/src/pages/goods_detail/components/top/innerComponents/AddToCartBar/WishBtn.vue'
import WishBtnV2 from 'public/src/pages/goods_detail_v2/innerComponents/top/AddToCartBar/WishBtn.vue'
import { isSwitchBffDetail } from 'public/src/pages/common/bff-new/index.js'
import { Icon } from '@shein-aidc/icon-vue2'
const headerAnalysis = new ControlAsync({
  file: () => import(/* webpackChunkName: "header-analysis" */ '../analysis/index.js')
}).run()
const language = computed(() => gbCommonInfo.language)
const is_use_bff_fs = computed(() => isSwitchBffDetail(['vBFF_FS']))

const { goodsDetailInfo } = useGlobalMapState(['goodsDetailInfo'])

const collectipshowAbt = ref(0)
onMounted(async() => {
  const { collectandaddcart } = await getUserAbtData()
  collectipshowAbt.value = collectandaddcart?.p?.collectipshow
  setMovedTips()
  headerAnalysis().then((res) => res.exposeShare(goodsDetailInfo.value))
})
// 清理定时器
onUnmounted(() => {
  if (timer) clearTimeout(timer)
  if (closeTimer) clearTimeout(closeTimer)
})

const cacheShowMovedTips = typeof localStorage !== 'undefined' && localStorage.getItem('showMovedTips')
const showMovedTips = ref(false)
let timer = null
let closeTimer = null

const setMovedTips = () => {
  // 只出现一次
  if (!(+collectipshowAbt.value) || cacheShowMovedTips) return
  if (timer) clearTimeout(timer)
  if (closeTimer) clearTimeout(closeTimer)
  typeof localStorage !== 'undefined' && localStorage.setItem('showMovedTips', 1)
  timer = setTimeout(() => {
    showMovedTips.value = true
  }, (+collectipshowAbt.value) * 1000)
  closeTimer = setTimeout(() => {
    showMovedTips.value = false
  }, (+collectipshowAbt.value + 5) * 1000)
}

const closeMovedTips = () => {
  showMovedTips.value = false
  typeof localStorage !== 'undefined' && localStorage.setItem('showMovedTips', 1)
  if (timer) clearTimeout(timer)
  if (closeTimer) clearTimeout(closeTimer)
}

</script>

<style lang="less" scoped>
.common-logo-header__wishlist {
  position: relative;
}
.moved-tip {
  position: absolute;
  top: 1.12rem;
  right: 0;
  white-space: nowrap;
  display: flex;
  height: 1.09333rem;
  padding: 0.32rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.21333rem;
  color: #FFF;
  font-family: "SF Pro";
  font-size: 0.37333rem;
  border-radius: 0.05333rem;
  background:  rgba(0, 0, 0, 0.80);
  &::before {
    content: '';
    position: absolute;
    top: -0.28rem;
    right: 5%;
    border-top: 0.16rem solid transparent;
    border-right: 0.16rem solid transparent;
    border-bottom: 0.16rem solid rgba(0, 0, 0, 0.80);
    border-left: 0.16rem solid transparent;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.05rem;
  }
}
</style>
