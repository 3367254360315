<template>
  <div
    v-show="!rootStatus.exchangingSpaScene"
    id="app"
  >
    <TopBanner
      v-if="IS_NEW_HEAD"
      v-show="showTopBanner"
      style="position: relative; z-index: 2;"
      :channelId="channelId"
      :topTabList="topTabList"
      @cccBannerHide="handleTopBannerHide"
      @cccBannerShow="handleTopBannerShow"
    /> 
    <AppHeader
      v-if="IS_NEW_HEAD"
      v-show="showAppHeader"
      :showAppHeader="showAppHeader"
      :cccSkinHeaderLogo="cccSkinHeaderLogo"
      :commonHeight="commonHeight"
      :commonHeaderBg="commonHeaderBg"
      :showImmersiveBanner="showImmersiveBanner"
      :isAddCommonHeaderClassName="isAddCommonHeaderClassName"
      :messageViewMode="messageViewMode"
      @changeHomeImmersiveInfo="changeHomeImmersiveInfo"
    />
    <div id="prerender-in-app"></div>
    <div
      v-show="!rootStatus.exchangingSpaSceneInApp"
      id="in-app"
    >
      <keep-alive>
        <router-view
          v-if="$route.meta && $route.meta.keepAlive"
          :key="$route.name"
        />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
      <client-only>
        <similar-product-vue v-if="rootStatus.importSimilarVue" />

        <lazy-mount
          v-if="rootStatus"
          mount-prop-name="show"
        >
          <s-loading
            class="root-loading"
            :show="rootStatus.loading"
            type="curpage"
          />
        </lazy-mount>

        <!--
          可用以下 root mutations 变更状态, 参数格式同组件
          containerCoverStyle 和 curPageMaskTopBoundSelector 在loading关闭后自动恢复默认
          this.changeRootSLoadingStatus({
            show: false,
            containerCoverStyle: {}, // 可选
            curPageMaskTopBoundSelector: '' // 可选
            type: 'curpage',
            curPageScrollFix: true, // 滚动条是否可滚动 默认禁止滚动
          })
        -->

        <lazy-mount
          v-if="rootStatus"
          mount-prop-name="show"
        >
          <s-loading
            :show="rootSLoading.show"
            :container-cover-style.sync="rootSLoading.containerCoverStyle"
            :cur-page-mask-top-bound-selector.sync="rootSLoading.curPageMaskTopBoundSelector"
            :type="rootSLoading.type"
            :cur-page-scroll-fix="rootSLoading.curPageScrollFix"
            :mask-transparent="rootSLoading.maskTransparent"
          />
        </lazy-mount>
      </client-only>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import ClientOnly from 'vue-client-only'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { install } from 'public/src/pages/common/similar_product_modal/index.js'
import { useLoading } from 'public/src/pages/common/requestLoading.js'
import { installAppHeader, installAppTopBanner, installAppPolicyBanner, installAppBranch } from 'public/src/pages/common/banner/exampleBanner.js'
import ControlHeader from 'public/src/pages/common/banner/controlHeader.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import LazyMount from 'public/src/pages/components/ccc/base/LazyMount.js'
import AppFooter from './footer/index.vue'
import AppHeader from 'public/src/pages/components/app_header/index.vue'
import SILog from 'public/src/pages/common/monitor/index'
import { BTopBanner as TopBanner } from '@shein-aidc/bs-top-banner-mobile-vue2'
import { parseQueryString } from '@shein/common-function'

export default {
  name: 'ProductApp',
  components: {
    ClientOnly,
    LazyMount,
    AppFooter,
    AppHeader,
    similarProductVue: () => import(/* webpackChunkName: "similar-product" */ './../common/similar_product_modal/similar_product_modal.vue'),
    TopBanner,
  },
  data () {
    return {
      history: typeof history !== 'undefined' ? history : undefined,
      showCategory: false,
    }
  },
  computed: {
    // ...mapState(['redpoint', 'commonHeaderBg', 'commonHeight']),
    ...mapState(['IS_NEW_HEAD', 'rootStatus', 'cccSkinHeaderLogo', 'rootSLoading', 'commonHeaderBg', 'showImmersiveBanner', 'commonHeight', 'isAddCommonHeaderClassName', 'messageViewMode']),
    ...mapGetters('config_index', ['channelId', 'topTabList']),
    // showBanner() {
    //   return this.$route.name
    //     ? this.$route.name != 'config_index'
    //     : (typeof gbCommonInfo !== 'undefined' && gbCommonInfo?.contextForSSR?.ssrPageType !== 'config_index')
    // }
    showAppHeader() {
      return this.getShowAppHeader(this.$route)
    },
    showTopBanner() {
      return this.handleTopBannerShowByPage(this.$route)
    },
  },
  watch: {
    $route (to, from) {
      this.$nextTick(() => {
        if (from.query.from === 'popupScanResult' && window.appRouteExtData.direction === -1) {
          appEventCenter.$emit('openSearchAndPopupScanResult')
        }
        // if (from.query.from === 'popupScanResult' && window.appRouteExtData.direction !== -1 && !from.meta?.cameraFrom) {
        //   appEventCenter.$emit('hiddenMiddlePage')
        // }

        if (from.query.ici && from.query.ici.indexOf('CameraSearch') > -1 && window.appRouteExtData.direction === -1) {
          appEventCenter.$emit('openMiddlePage')
        }
        if (from.query.ici && from.query.ici.indexOf('CameraSearch') > -1 && window.appRouteExtData.direction !== -1) {
          appEventCenter.$emit('hiddenMiddlePage')
        }
        this.initRedpointCheck(to.name)
      })
    },
  },
  async mounted () {
    const self = this

    // 判断当前页面是否属于营销来源
    const { url_from = '', aff_id = '', onelink = '', scene = '', page_version = '' } = parseQueryString(location.search)
    const isMarketing = url_from || aff_id || onelink || scene === 'onelink'

    window.fspSDK.updateOptions({
      reporterHandler({ name, value, payload = {} } = {}) {
        if (!name || window.isClientBot) {
          return
        }

        const resource = payload.resource || ''
        let pageName = window?.SaPageInfo?.page_name || ''
        // 针对营销来源到达商详的流量, 上报 page_name 时, 增加标识后缀
        const marketingLandingPageNames = ['page_goods_detail', 'page_advertising']
        if (marketingLandingPageNames.includes(pageName) && isMarketing) {
          pageName += '_from_marketing'
        }
        if (self.$route?.name === 'campaigns' && value > 3000) {
          try {
            const fileds = SILog.getFields()
            SILog.SIMetric.metricCount({
              metric_name: 'activity_long_fsp_total',
              tags: {
                site: window?.gbCommonInfo?.SiteUID || '',
                identity: self.$route?.params?.identity || '',
                pathname: location.pathname,
                os_name: fileds?.os_name || '',
                device_model: fileds?.device_model || '',
                fspValue: value,
              },
            }, {
              immediate: true,
            })
            // window.ErrorJs.sendError('error', `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`, {
            //   appName: 'shein_PWA_new',
            //   errorMark: 'Activity_Long_FSP',
            //   error_type: 'script_error',
            //   errorInfo: {
            //     identity: self.$route?.params?.identity,
            //     fspValue: value,
            //     pathname: self.$route?.path,
            //     href: location.href,
            //   },
            //   reportList: [
            //     {
            //       metricName: 'activity_long_fsp_total',
            //       site: gbCommonInfo?.SiteUID,
            //       identity: self.$route?.params?.identity,
            //       pathname: self.$route?.path,
            //     },
            //   ]
            // })
          } catch (error) {
            console.log('error', error)
          }
        }
        // 自定义场景，登录弹窗 dialog_login
        let customSceneName = ''
        if (resource === 'custom-scene' && payload?.customSceneContainer) {
          let customSceneContainer = payload?.customSceneContainer
          if (customSceneContainer instanceof Element || customSceneContainer instanceof HTMLDocument) {
            customSceneContainer = customSceneContainer.getAttribute('data-customSceneName')
          }
          if (customSceneContainer && typeof customSceneContainer === 'string') {
            customSceneName = customSceneContainer
          }
        }

        const pageFrom = isMarketing ? 'Marketing' : 'Home'
        const pageVersion = page_version || 'Other'
        const renderType = resource === 'ssr-landing-page' ? 'ssr' : 'spa'

        const data = {
          resource,
          page_from: pageFrom,
          page_version: pageVersion,
          render_type: renderType,
          data: [
            {
              key_path: name,
              values: {
                // 弹窗组件部分有更新,默认弹出时间为 150ms
                num: customSceneName === 'dialog_login' ? value + 150 : value
              }
            },
          ],
          // 1. 固定 page_name 防止上报时 page_name 已经被篡改
          ...(pageName ? { page_name: pageName } : {}),
          // 2. 弹窗类型 替换 page_name 为 弹窗对应的 name
          ...(customSceneName ? { page_name: customSceneName } : {}),
        }
        window.TPM?.run({
          marketing: 'ClientAnalytics',
          method: '_defineTrack',
          params: {
            data,
            options: {
              random: 1,
              immediate: true,
            },
          }
        })
      }
    })

    window.fspSDK.observeRouteChange(this.$router)
    this.installHeaderDom()

    if (this.$route && this.$route.meta && this.$route.meta.monitorTag && typeof window !== 'undefined') {
      window.pageTypeForMonitor = this.$route.meta.monitorTag
    }
    // 【not delete】fps automated testing
    window.appVueInstance = this

    this.initRedpointCheck(this.$route.name)

    install(() => {
      this.changeRootStatus({
        importSimilarVue: true
      })
    })
    this.prefetchResources()
  },

  created() {
    useLoading(({ show, type, containerCoverStyle = {}, curPageScrollFix = true, maskTransparent = false }) => {
      this.changeRootSLoadingStatus({ show, type, containerCoverStyle, curPageScrollFix, maskTransparent })
    })
  },

  methods: {
    ...mapActions(['fetchIndividuation', 'checkRedpoint']),
    ...mapMutations(['changeRootStatus', 'changeRootSLoadingStatus', 'changeHomeImmersiveInfo', 'changeNewTopBannerStatus']),

    getShowAppHeader(route) {
      const mainPage = [
        'config_index',
        'page_category',
        'product-list-v2',
        'shein-picks',
        'page_coupon_product',
        'picked-info-list',
        'page_goods_detail',
        'config_activity',
        'page_flash_sale',
        'flash_sale',
        'feedback-rec-list',
        'campaigns',
        'page_advertising',
        'wishlistGroupShare',
        'page_store',
      ]
      const isActivityDesignerPage = route.name === 'config_activity' && route.path?.indexOf('designer') >= 0
      return mainPage.includes(route.name)
        && !isActivityDesignerPage
        && this.IS_NEW_HEAD
        && !route.meta?.notNeedCommonHeader
    },
    handleTopBannerShowByPage(route) {
      const pageShow = ['index', 'productDetail', 'category', 'productList', 'page_store', 'activity', 'flash_sale', 'PTRecProductList', 'PTFeedbackRecList', 'campaigns'].includes(route.meta.pageType) || ['wishlistGroupShare'].includes(route.name)
      return this.IS_NEW_HEAD && pageShow
    },
    handleTopBannerHide() {
      this.changeNewTopBannerStatus({ hasNewTopBanner: false })
    },
    handleTopBannerShow() {
      this.changeNewTopBannerStatus({ hasNewTopBanner: true })
    },
    installHeaderDom() {
      // 只控制页面的展示，不控制页面的逻辑
      const controlHeader = new ControlHeader({
        components: [ // 顺序和渲染的顺序保持一致。
          {
            displayDom: '#branch-app', // 切换展示dom
            install: async () => {
              return installAppBranch({ router: this.$router, store: this.$store, app: this })
            },
            onCheck: () => {
              return true // 组件内部自己控制
            }
          },
          {
            displayDom: '#top-banner',
            install: () => {
              return installAppTopBanner({ el: '#top_banner_app', router: this.$router, store: this.$store, channelId: this.channelId })
            },
            onCheck: (route) => {
              if (this.IS_NEW_HEAD) return false
              return ['index', 'productDetail', 'category', 'productList', 'page_store', 'activity', 'flash_sale', 'PTRecProductList', 'PTFeedbackRecList', 'campaigns'].includes(route.meta.pageType) || ['wishlistGroupShare'].includes(route.name)
            },
          },
          {
            displayDom: '#header-app',
            install: () => {
              return installAppHeader({ el: '#header-page', router: this.$router, store: this.$store, showcategory: () => {
                this.showcategoryHandle()
              } })
            },
            onCheck: (route) => {
              if (this.IS_NEW_HEAD) return false
              return !route?.meta?.notNeedCommonHeader
            }
          },
          {
            displayDom: '#shein-policy-app',
            install: () => {
              return installAppPolicyBanner({ el: '#j-policy-banner', router: this.$router, store: this.$store })
            },
            onCheck: (route) => {
              if (this.IS_NEW_HEAD) return false
              return ['productDetail', 'category', 'productList', 'page_store', 'activity', 'flash_sale', 'PTRecProductList', 'PTFeedbackRecList'].includes(route.meta.pageType) || ['wishlistGroupShare'].includes(route.name)
            },
          },
        ],
      })
      controlHeader.init(this.$route)
      const unwatch = this.$watch('$route', (to) => {
        controlHeader.update(to)
      })
      // 在销毁组件前调用取消监听函数，防止内存泄漏
      this.$once('hook:beforeDestroy', unwatch)
    },

    /**
     * 初始化红点检测逻辑
     * @description 白名单内的页面不执行红点检测，检测完触发 repointCheckDone 事件
     */
    initRedpointCheck (routeName) {
      const notNeedRouteNames = ['pre_search']
      if (notNeedRouteNames.includes(routeName)) return
      requestIdleCallback(() => {
        this.checkRedpoint(() => {
          appEventCenter.$emit('redpointCheckDone')
        })
      })
    },

    showcategoryHandle() {
      // first load sidecate code, seconde show sidecate
      // !this.showCategory ? this.showCategory = true : this.$refs?.sidecategory?.clickSideIconEvent()
    },
    prefetchResources() {
      if (this.$route.name === 'cart') return
      // el 必须是渲染完的
      requestIdleCallback(() => {
        prefetchResource.listen({
          el: this.$el,
          prefetchList: [
            {
              chunkName: 'cart_index_new',
              relType: 'prefetch'
            },
            {
              chunkName: 'cart_header_checkout',
              relType: 'prefetch'
            },
            {
              chunkName: 'cart_bottom_content',
              relType: 'prefetch'
            },
            {
              chunkName: 'pre_search',
              relType: 'prefetch'
            },
            {
              chunkName: 'plv2_container',
              relType: 'prefetch'
            },
            {
              chunkName: 'user-index',
              relType: 'prefetch'
            },
            {
              chunkName: 'cartv2_index',
              relType: 'prefetch'
            },
            {
              chunkName: 'cartv2_bottom_content',
              relType: 'prefetch'
            },
          ],
          delay: 2000, // 默认两秒
          prefetchCallback: () => {
            setTimeout(() => {
              const loadComponent = prefetchResource.importAsyncComponent({
                chunkName: 'plv2_container', // 跟webpackChunkName保持一致
                componentFactory: () => import( /* webpackChunkName: 'plv2_container' */ 'public/src/pages/product_list_v2/container.vue'),
              })
              loadComponent?.()
            }, 1000)
          },
        })
      })
    },
  }
}
</script>

<style lang="less">
// 详情页找相似 or 快速加车找相似上面的 售罄文案
.soldout-warning {
  padding: .213333rem .32rem;
  line-height: 1.2;
  background-color: #FFF1D5;
  .font-dpr(24px);
  color: #222;
  text-align: center;
  /* rw:begin */
  background-color: @sui_color_black;
  color: @sui_color_white;
  // 详情页
  &.goods-detial {
    position: absolute;
    width: 100%;
    top: 0;
    transform: translateY(-100%);
    /* rtl:begin:ignore */
    left: 0;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .28s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

// 触觉反馈优化 当前只针对shein
[brd=sh] .haptics-feedback:active {
  background: #F6F6F6;
}
</style>
